
<style scoped>
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}
.greyclr{ color: lightgray !important;}
</style>

<template>
  <div>
     <v-flex>
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-2 pt-0">
           <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-select height="30" dense :items="symbolsList" v-model="instrument"  outlined  hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
             <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                   <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense disabled v-model="selectdate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" hide-details label="Select Date" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="selectdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectdate)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
               
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class=" ">
                  <v-select v-model="timedata" :items="timeinterval" outlined dense hide-details label="Time Interval" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
               <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
    <v-flex xs12 class="pa-2 pt-0  pl-2">
      <v-container fluid class="charthalfview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg6 xl6 class="pa-0 pt-0 px-0">
            <v-card class="pa-0 a-box">
                      <v-row class="ma-0" width="fit-content">
                  <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="ceStrikeprice" 
                      :items="strickPriceList" dense outlined hide-details label="Select CE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                   <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="peStrikeprice"
                      :items="strickPriceList" dense outlined hide-details label="Select PE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                  <v-col class="d-flex align-center justify-end">
                 <div class="text-right subtitle-1 font-weight-bold">{{instrument}}
                  <span class="ml-1">{{ceStrikeprice ? ceStrikeprice + "CE X" : ''}} {{peStrikeprice ? peStrikeprice + "PE" : ''}}</span></div>
                  </v-col>
               </v-row>
              <v-chart class="chart" autoresize :options="peceCloseChart" />
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md12 lg6 xl6 class="px-0 pl-2 pt-0">
            <v-card class="pa-0 a-box">
                    <v-row class="ma-0" width="fit-content">
                  <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="ceStrikeprice1"
                      :items="strickPriceList" dense outlined hide-details label="Select CE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                   <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="peStrikeprice1"
                      :items="strickPriceList" dense outlined hide-details label="Select PE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
               <v-col class="d-flex align-center justify-end">
               <div class="text-right subtitle-1 font-weight-bold  ">{{instrument}}
               <span class="ml-1">{{ceStrikeprice1 ? ceStrikeprice1 + "CE X" : ''}} {{peStrikeprice1 ? peStrikeprice1 + "PE" : ''}}</span></div>
                  </v-col>
               </v-row>
              <v-chart class="chart" autoresize :options="peceCloseChart1" />
            </v-card>
          </v-flex>
          </v-layout
      ></v-container>
    </v-flex>

    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid class="charthalfview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg6 xl6 class="pa-0 pt-0 px-0">
            <v-card class="pa-0 a-box">
                      <v-row class="ma-0" width="fit-content">
                  <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="ceStrikeprice2"
                      :items="strickPriceList" dense outlined hide-details label="Select CE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                   <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="peStrikeprice2"
                      :items="strickPriceList" dense outlined hide-details label="Select PE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                <v-col class="d-flex align-center justify-end">
                <div class="text-right subtitle-1 font-weight-bold">{{instrument}}
                  <span class="ml-1">{{ceStrikeprice2 ? ceStrikeprice2 + "CE X" : ''}} {{peStrikeprice2 ? peStrikeprice2 + "PE" : ''}}</span></div>
                  </v-col>
               </v-row>
              <v-chart class="chart" autoresize :options="peceCloseChart2" />
            </v-card>
          </v-flex>
            <v-flex xs12 sm12 md12 lg6 xl6 class="px-0 pl-2 pt-0">
            <v-card class="pa-0 a-box">
                    <v-row class="ma-0" width="fit-content">
                  <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="ceStrikeprice3"
                      :items="strickPriceList" dense outlined hide-details label="Select CE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                   <v-col class="" cols="6" sm="3" xs="12">
                      <v-select  v-model="peStrikeprice3"
                      :items="strickPriceList" dense outlined hide-details label="Select PE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                  </v-col>
                <v-col class="d-flex align-center justify-end">
                <div class="text-right subtitle-1 font-weight-bold">{{instrument}}
                  <span class="ml-1">{{ceStrikeprice3 ? ceStrikeprice3 + "CE X" : ''}} {{peStrikeprice3 ? peStrikeprice3 + "PE" :''}}</span></div>
                  </v-col>
               </v-row>
              <v-chart class="chart" autoresize :options="peceCloseChart3" />
            </v-card>
          </v-flex> </v-layout>
          </v-container>
    </v-flex>
  </div>
</template>
<script>
import VChart from "vue-echarts";
import "@/plugins/echart";
import { mapState } from "vuex"
// import instrumentHistory from "@/mixins/instrumentHistory";
import commonFunctions from "@/mixins/commonFunctions";
import { gql } from "graphql-tag";

const INSTRUMENTS_HISTORY_QUERY = gql`
  query instrumentsHistory($instruments: [String]) {
    instrumentsHistory(instruments: $instruments) {
      instrument
      data {
        INSTRUMENTIDENTIFIER
        OPEN
        HIGH
        LOW
        CLOSE
        OPENINTEREST
        TRADEDQTY
        EXCHANGE
        LASTTRADETIME
      }
    }
  }
`;
const INSTRUMENTS_SUBSCRIPTION = gql`
  subscription subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
const INSTRUMENTS_QUERY = gql`
  query subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
let subscribedInstruments = {
  INSTRUMENTIDENTIFIER: "",
  OPEN: "0.0",
  HIGH: "0.0",
  LOW: "0.0",
  CLOSE: "0.0",
};

let instrumentsHistory = {};
export default {
  mixins:[commonFunctions],
  components: {
    VChart,
  },
  // provide: {
  //   [THEME_KEY]: "light",
  // },

  data() {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      submenu: false,
      modal: false,
      menu2: false,
      instrument: "BANKNIFTY",
      expirydate: "11NOV21",
      ceStrikeprice: null,
      peStrikeprice: null,
      ceStrikeprice1: null,
      peStrikeprice1:null,
      ceStrikeprice2: null,
      peStrikeprice2:null,
      ceStrikeprice3: null,
      peStrikeprice3:null,
      singlestangledatace: null,
      singlestangledatape:null,
      selectedIndex: 0,
      timedata: "1MIN",
      timeinterval: ["1MIN"],

      peceCloseChart: {
        tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            data: [],
            symbolSize: 3,
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            data: [],
            symbolSize: 3,
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            data: [],
            symbolSize: 3,
          },
        ],
      },

      peceCloseChart1: {
        tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            data: [],
            symbolSize: 3,
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
        ],
      },

      peceCloseChart2: {
         tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
             magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            data: [],
             symbolSize: 3,
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            data: [],
             symbolSize: 3,
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            data: [],
             symbolSize: 3,
          },
        ],
      },

      peceCloseChart3: {
        tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
             magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            data: [],
            symbolSize: 3,
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            data: [],
            symbolSize: 3,
          },
        ],
      },
    };
  },
    apollo: {
    instrumentsHistory: {
      query: INSTRUMENTS_HISTORY_QUERY,
      variables() {
        return {
          instruments: [
            `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_PE_${this.singlestangledatape}`,
            `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_CE_${this.singlestangledatace}`,
          ],
        };
      },
      update: (data) => {
        instrumentsHistory = data.instrumentsHistory;
        return instrumentsHistory;
      },

        result() {
        console.log(instrumentsHistory)
        if (this.selectedIndex == 0) {
          this.peceCloseChart.xAxis.data = [];
          this.peceCloseChart.series[0].data = [];
          this.peceCloseChart.series[1].data = [];
          this.peceCloseChart.series[2].data = [];
        }
        else if (this.selectedIndex == 1) {
          this.peceCloseChart1.xAxis.data = [];
          this.peceCloseChart1.series[0].data = [];
          this.peceCloseChart1.series[1].data = [];
          this.peceCloseChart1.series[2].data = [];
        }
        else if (this.selectedIndex == 2) {
          this.peceCloseChart2.xAxis.data = [];
          this.peceCloseChart2.series[0].data = [];
          this.peceCloseChart2.series[1].data = [];
          this.peceCloseChart2.series[2].data = [];
        }
        else if (this.selectedIndex == 3) {
          this.peceCloseChart3.xAxis.data = [];
          this.peceCloseChart3.series[0].data = [];
          this.peceCloseChart3.series[1].data = [];
          this.peceCloseChart3.series[2].data = [];
        }
        var pedata = instrumentsHistory[0].data;
        var cedata = instrumentsHistory[1].data;
        var lastTradeTime = [];
        var pedata2 = [];
        var cedata2 = [];
        var combined = [];
        pedata.forEach((elementPe) => {
          cedata.forEach((elementCe) => {
            if (elementPe.LASTTRADETIME == elementCe.LASTTRADETIME) {
              lastTradeTime.push(elementCe);
              pedata2.push(elementPe);
              cedata2.push(elementCe);
              combined.push((elementPe.CLOSE + elementCe.CLOSE).toFixed(2));
    
            }
          });
        });
        console.log(lastTradeTime)
        lastTradeTime.forEach((element) => {

          if (this.selectedIndex == 0) {
             this.peceCloseChart.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }

          else if(this.selectedIndex == 1) {
             this.peceCloseChart1.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }
         
         else if(this.selectedIndex == 2) {
             this.peceCloseChart2.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }

         else if(this.selectedIndex == 3) {
             this.peceCloseChart3.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }
        });

        pedata2.forEach((element) => {
         if (this.selectedIndex == 0) {
            this.peceCloseChart.series[1].data.push(element.CLOSE);
          } 
         else if (this.selectedIndex == 1) {
            this.peceCloseChart1.series[1].data.push(element.CLOSE);
          } 
         else if (this.selectedIndex == 2) {
            this.peceCloseChart2.series[1].data.push(element.CLOSE);
          } 
         else if (this.selectedIndex == 3) {
            this.peceCloseChart3.series[1].data.push(element.CLOSE);
          } 
        });

        cedata2.forEach((element) => {
         if (this.selectedIndex == 0) {
            this.peceCloseChart.series[2].data.push(element.CLOSE);
          } 
          else if (this.selectedIndex == 1) {
            this.peceCloseChart1.series[2].data.push(element.CLOSE);
          } 
          else if (this.selectedIndex == 2) {
            this.peceCloseChart2.series[2].data.push(element.CLOSE);
          } 
          else if (this.selectedIndex == 3) {
            this.peceCloseChart3.series[2].data.push(element.CLOSE);
          } 
        });

        combined.forEach((element) => {
         if (this.selectedIndex == 0) {
            this.peceCloseChart.series[0].data.push(element);
          }
          else if(this.selectedIndex == 1) {
            this.peceCloseChart1.series[0].data.push(element);
          }
          else if(this.selectedIndex == 2) {
            this.peceCloseChart2.series[0].data.push(element);
          }
          else if(this.selectedIndex == 3) {
            this.peceCloseChart3.series[0].data.push(element);
          }
        });
      },
    },
    // subscribedInstruments: {
    //   query: INSTRUMENTS_QUERY,
    //   subscribeToMore: {
    //     document: INSTRUMENTS_SUBSCRIPTION,
    
    //     variables() {
    //       return {
    //         instruments: [
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_PE_${this.peStrikeprice}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_PE_${this.peStrikeprice1}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_PE_${this.peStrikeprice2}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_PE_${this.peStrikeprice3}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_CE_${this.ceStrikeprice}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_CE_${this.ceStrikeprice1}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_CE_${this.ceStrikeprice2}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace("21", "2021") : ''}_CE_${this.ceStrikeprice3}`
    //          ],
    //       };
    //     },
    //     // Mutate the previous result

    //     updateQuery: (previousResult, { subscriptionData }) => {
    //       return subscriptionData.data || subscribedInstruments;
    //     },

    //     result(previousResult, { subscriptionData }) {
    //      if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.peStrikeprice}`){
    //          localStorage.setItem("mstPeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart1()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.peStrikeprice1}`){
    //          localStorage.setItem("mst1PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart2()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.peStrikeprice2}`){
    //          localStorage.setItem("mst2PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart3()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.peStrikeprice3}`){
    //          localStorage.setItem("mst3PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart4()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.ceStrikeprice}`){
    //          localStorage.setItem("mstCeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart1()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.ceStrikeprice1}`){
    //          localStorage.setItem("mst1CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart2()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.ceStrikeprice2}`){
    //          localStorage.setItem("mst2CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart3()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.ceStrikeprice3}`){
    //          localStorage.setItem("mst3CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart4()
    //       }
    //     },
    //   },
    // },
  },
    computed: {
      ...mapState('symbols',['symbolsList','expiryList','strickPriceList','ceStrikePriceList','peStrikePriceList','currentStrikePrice']),
    },

   watch: { 
      instrument: function(val) {
        this.initialFunctions()
      },
      expirydate: function(val) {
        // this.initialFunctions()
        this.ceStrikeprice = this.peStrikeprice = this.ceStrikeprice1 = this.peStrikeprice1 
        = this.ceStrikeprice2 = this.peStrikeprice2 = this.ceStrikeprice3 = this.peStrikeprice3  = null
      },
      ceStrikeprice: function (val) {
         this.singlestangledatace = val;
         this.ceStrikeprice && this.peStrikeprice ? this.selectedIndex = 0 : this.selectedIndex = null 
      },
      peStrikeprice: function (val) {
          this.singlestangledatape = val;
          this.ceStrikeprice && this.peStrikeprice ? this.selectedIndex = 0 : this.selectedIndex = null 
      },
      ceStrikeprice1: function (val) {
          this.singlestangledatace = val;
          this.ceStrikeprice1 && this.peStrikeprice1 ? this.selectedIndex = 1 : this.selectedIndex = null 
      } ,
      peStrikeprice1: function (val) {
          this.singlestangledatape = val;
          this.ceStrikeprice1 && this.peStrikeprice1 ? this.selectedIndex = 1 : this.selectedIndex = null 
      },
      ceStrikeprice2: function (val) {
          this.singlestangledatace = val;
          this.ceStrikeprice2 && this.peStrikeprice2 ? this.selectedIndex = 2 : this.selectedIndex = null 
      },
      peStrikeprice2 : function (val) {
          this.singlestangledatape = val;
          this.ceStrikeprice2 && this.peStrikeprice2 ? this.selectedIndex = 2 : this.selectedIndex = null
      },
      ceStrikeprice3: function (val) {
          this.singlestangledatace = val;
          this.ceStrikeprice3 && this.peStrikeprice3 ? this.selectedIndex = 3 : this.selectedIndex = null
      },
      peStrikeprice3: function (val) {
          this.singlestangledatape = val;
          this.ceStrikeprice3 && this.peStrikeprice3 ? this.selectedIndex = 3 : this.selectedIndex = null
      },
    },

    methods: {
      async initialFunctions() {
         await this.$store.dispatch('symbols/getSymbolsList');
         await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
         await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
         this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
         this.ceStrikeprice = this.peStrikeprice = await this.getCurrentPrice(this.currentStrikePrice)
      },
      chart1() {
        var mstPeData = JSON.parse(localStorage.getItem("mstPeData"))
        var mstCeData = JSON.parse(localStorage.getItem("mstCeData"))
          // console.log(st1PeData.LASTTRADETIME === st1CeData.LASTTRADETIME)
          if(!!mstPeData.LASTTRADETIME && !!mstCeData.LASTTRADETIME && mstPeData.LASTTRADETIME == mstCeData.LASTTRADETIME) {
            if(this.peStrikeprice && this.ceStrikeprice) {
             this.peceCloseChart.xAxis.data.push(this.unixToTime(mstCeData.LASTTRADETIME))
             this.peceCloseChart.series[0].data.push((mstPeData.CLOSE + mstCeData.CLOSE).toFixed(2))
             this.peceCloseChart.series[1].data.push(mstPeData.CLOSE)
             this.peceCloseChart.series[2].data.push(mstCeData.CLOSE)
             }
          }
      },
      chart2() {
        var mst1PeData = JSON.parse(localStorage.getItem("mst1PeData"))
        var mst1CeData = JSON.parse(localStorage.getItem("mst1CeData"))
          // console.log(st1PeData.LASTTRADETIME === st1CeData.LASTTRADETIME)
          if(!!mst1PeData.LASTTRADETIME && !!mst1CeData.LASTTRADETIME && mst1PeData.LASTTRADETIME == mst1CeData.LASTTRADETIME) {
            if(this.peStrikeprice1 && this.ceStrikeprice1) {
             this.peceCloseChart1.xAxis.data.push(this.unixToTime(mst1CeData.LASTTRADETIME))
             this.peceCloseChart1.series[0].data.push((mst1PeData.CLOSE + mst1CeData.CLOSE).toFixed(2))
             this.peceCloseChart1.series[1].data.push(mst1PeData.CLOSE)
             this.peceCloseChart1.series[2].data.push(mst1CeData.CLOSE)
             }
          }
      },
      chart3() {
        var mst2PeData = JSON.parse(localStorage.getItem("mst2PeData"))
        var mst2CeData = JSON.parse(localStorage.getItem("mst2CeData"))
          if(!!mst2PeData.LASTTRADETIME && !!mst2CeData.LASTTRADETIME && mst2PeData.LASTTRADETIME == mst2CeData.LASTTRADETIME) {
            if(this.peStrikeprice2 && this.ceStrikeprice2) {
             this.peceCloseChart2.xAxis.data.push(this.unixToTime(mst2CeData.LASTTRADETIME))
             this.peceCloseChart2.series[0].data.push((mst2PeData.CLOSE + mst2CeData.CLOSE).toFixed(2))
             this.peceCloseChart2.series[1].data.push(mst2PeData.CLOSE)
             this.peceCloseChart2.series[2].data.push(mst2CeData.CLOSE)
             }
          }
      },
      chart4() {
        var mst3PeData = JSON.parse(localStorage.getItem("mst3PeData"))
        var mst3CeData = JSON.parse(localStorage.getItem("mst3CeData"))
          if(!!mst3PeData.LASTTRADETIME && !!mst3CeData.LASTTRADETIME && mst3PeData.LASTTRADETIME == mst3CeData.LASTTRADETIME) {
            if(this.peStrikeprice2 && this.ceStrikeprice2) {
             this.peceCloseChart3.xAxis.data.push(this.unixToTime(mst3CeData.LASTTRADETIME))
             this.peceCloseChart3.series[0].data.push((mst3PeData.CLOSE + mst3CeData.CLOSE).toFixed(2))
             this.peceCloseChart3.series[1].data.push(mst3PeData.CLOSE)
             this.peceCloseChart3.series[2].data.push(mst3CeData.CLOSE)
             }
          }
      },
    },
    
    mounted() {
      //this.option.color = this.global.chart.defaultTheme.color;
      this.initialFunctions()
      this.peceCloseChart.series[2].color = this.peceCloseChart1.series[2].color = this.peceCloseChart2.series[2].color = this.peceCloseChart3.series[2].color = "#4caf50";
      this.peceCloseChart.series[1].color = this.peceCloseChart1.series[1].color = this.peceCloseChart2.series[1].color = this.peceCloseChart3.series[1].color = "#ff5e5e";
  },  
};
</script>
