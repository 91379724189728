<style scoped>
.v-btn:not(.v-btn--round).v-size--default {height: 40px; min-width: 64px;padding: 0 16px;}
</style>



<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md6 lg6 xl6 class="pa-2">
          <v-card class="a-box pa-0" width="fit-content">
            <span class="grey">
              <v-tabs height="36" v-model="tab" centered class="horizontal-tab tabsegment" tag="button"  >
                <v-tab height="28" color="#ffffff" :centered="true" @click="tabschange('singlestraddle')">Single Straddle</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabschange('multistraddle')">Multi Straddle</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabschange('singlestrangle')">Single Strangle</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabschange('multistrangle')">Multi Strangle</v-tab>
              </v-tabs>
            </span>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="singlestraddle"><singlestraddle /></div>
    <div v-if="multistraddle"><multistraddle /></div>
    <div v-if="singlestrangle"><singlestrangle /></div>
    <div v-if="multistrangle"><multistrangle /></div>
  </div>
</template>
<script>
// import VChart, { } from "vue-echarts";
// import "@/plugins/echart";
import Multistraddle from "@/views/options/straddlechartoptions/multistraddle.vue";
import Singlestrangle from "@/views/options/straddlechartoptions/singlestrangle.vue";
import Multistrangle from '@/views/options/straddlechartoptions/multistrangle.vue';
import Singlestraddle from '@/views/options/straddlechartoptions/singlestraddle.vue';

export default {
  components: {
    
    Multistraddle,
    Singlestrangle,
    Multistrangle,
    Singlestraddle,
  },

  data() {
    return {
      singlestraddle: true,
      singlestrangle: false,
      multistraddle: false,
      multistrangle: false,
      expand: false,
      tab:0,
   
    };
  },

  methods: {
   tabReset(){ this.singlestraddle = this.multistraddle = this.singlestrangle = this.multistrangle = false;},
   tabschange(selectedTab) {
    this.tabReset();
    this[selectedTab] = true;
    selectedTab == 'singlestarddle' ? this.tab = 0 : selectedTab == 'multistraddle' ? this.tab = 1 
    : selectedTab == 'singlestrangle' ? this.tab = 2 : selectedTab == 'multistrangle' ? this.tab = 3 : this.tab = 0 
    this.$router.push({ path: this.$router.history.current.fullPath, query: { tab: selectedTab } }).catch(() => {});
   },
  },
  
  mounted() {
     var currenttab = this.$route.query.tab;
     currenttab ? this.tabschange(currenttab) : '';
  }
};
</script>

