<style scoped>
.v-input__control {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
  max-width: 200px !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
  min-width: 64px;
  padding: 0 16px;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  height: 14px;
  min-width: 36px;
  padding: 0 8.8888888889px;
}
.greyclr{ color: lightgray !important;}
</style>


<template>
  <div>
    <v-flex>
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-2 pt-0">
            <v-card class="a-box">
              <v-row class="ma-0" width="fit-content">
                <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                  <div class="">
                    <v-select
                      height="30"
                      dense
                      :items="symbolsList"
                      v-model="instrument"
                      outlined
                      hide-details
                      label="Instrument Name"
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                  <div class="">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          disabled
                          v-model="selectdate"
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          hide-details
                          label="Select Date"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="selectdate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(selectdate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                  <v-select
                    v-model="expirydate"
                    :items="expiryList"
                    dense
                    outlined
                    hide-details
                    label="​Expiry Date"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                  <div class="">
                    <v-select
                     v-model="timedata"
                      :items="timeinterval"
                      dense
                      outlined
                      hide-details
                      label="Time Interval"
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                  <v-btn color="primary" dense>Go</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-flex xs12 class="pa-2 pt-0 pb-0 pl-2">
      <v-container fluid class="charthalfview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg6 xl6 class="pa-2 pt-0 px-0">
            <v-card class="pa-0 a-box">
              <v-row class="ma-0" width="fit-content">
                <v-col class="pa-0 ml-3 mt-3" cols="6" sm="3" xs="12">
                  <v-select
                    v-model="strikeprice"
                    :items="strickPriceList"
                    dense
                    outlined
                    hide-details
                    label="Select Strike"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                   <div class="text-right subtitle-1 font-weight-bold">{{instrument}}<span class="ml-1">{{strikeprice}}</span></div>
                </v-col>
              </v-row>
              <v-chart class="chart" autoresize :options="option" />
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6 class="px-0 pl-2 pt-0">
            <v-card class="pa-0 a-box">
              <v-row class="ma-0" width="fit-content">
                <v-col class="pa-0 ml-3 mt-3" cols="6" sm="3" xs="12">
                  <v-select
                    v-model="strikeprice2"
                    :items="strickPriceList"
                    dense
                    outlined
                    hide-details
                    label="Select Strike"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                   <div class="text-right subtitle-1 font-weight-bold">{{instrument}}<span class="ml-1">{{strikeprice2}}</span></div>
                </v-col>
              </v-row>
              <v-chart class="chart" autoresize :options="option2" />
            </v-card>
          </v-flex> </v-layout
      ></v-container>
    </v-flex>

    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid class="charthalfview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg6 xl6 class="pa-2 pt-0 px-0">
            <v-card class="pa-2 a-box">
              <v-row class="ma-0" width="fit-content">
                <v-col class="pa-0 ml-1 mt-1" cols="6" sm="3" xs="12">
                  <v-select
                    v-model="strikeprice3"
                    :items="strickPriceList"
                    dense
                    outlined
                    hide-details
                    label="Select Strike"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                   <div class="text-right subtitle-1 font-weight-bold">{{instrument}}<span class="ml-1">{{strikeprice3}}</span></div>
                </v-col>
              </v-row>
              <v-chart class="chart" autoresize :options="option3" />
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6 class="px-0 pl-2 pt-0">
            <v-card class="pa-2 a-box">
              <v-row class="ma-0" width="fit-content">
                <v-col class="pa-0 ml-1 mt-1" cols="6" sm="3" xs="12">
                  <v-select
                    v-model="strikeprice4"
                    :items="strickPriceList"
                    dense
                    outlined
                    hide-details
                    label="Select Strike"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                   <div class="text-right subtitle-1 font-weight-bold ">{{instrument}}<span class="ml-1">{{strikeprice4}}</span></div>
                </v-col>
              </v-row>
              <v-chart class="chart" autoresize :options="option4" />
            </v-card>
          </v-flex>
          </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VChart from "vue-echarts";
import "@/plugins/echart";
import { gql } from "graphql-tag";
import commonFunctions from "@/mixins/commonFunctions";

const INSTRUMENTS_HISTORY_QUERY = gql`
  query instrumentsHistory($instruments: [String]) {
    instrumentsHistory(instruments: $instruments) {
      instrument
      data {
        INSTRUMENTIDENTIFIER
        OPEN
        HIGH
        LOW
        CLOSE
        OPENINTEREST
        TRADEDQTY
        EXCHANGE
        LASTTRADETIME
      }
    }
  }
`;
const INSTRUMENTS_SUBSCRIPTION = gql`
  subscription subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
const INSTRUMENTS_QUERY = gql`
  query subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
let subscribedInstruments = {
  INSTRUMENTIDENTIFIER: "",
  OPEN: "0.0",
  HIGH: "0.0",
  LOW: "0.0",
  CLOSE: "0.0",
};
let instrumentsHistory = {};
export default {
  mixins: [commonFunctions],
  components: {
    VChart,
  },
  // provide: {
  //   [THEME_KEY]: "light",
  // },
  data() {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      submenu: false,
      modal: false,
      menu2: false,
      instrument: "BANKNIFTY",
      expirydate: "11NOV21",
      strikeprice: null,
      strikeprice2: null,
      strikeprice3: null,
      strikeprice4: null,
      strikedata: null,
      selectedIndex: 0,
      timedata: "1MIN",
      timeinterval: [ "1MIN"],
      option: {
       tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
      legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar", "pie"] },
            restore: { show: false },
            saveAsImage: { show: true , title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
             symbolSize: 3,
            data: [],
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
             symbolSize: 3,
            data: [],
          },
        ],
      },

      option2: {
        tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
          // dataView: { show: true, readOnly: false },
          magicType: { show: true, title: '' , type: ["line", "bar", "pie"] },
          restore: { show: false },
          saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            symbolSize: 3,
            
            data: [],
          },
          {
            name: "PUT PRICE",
            type: "line",
            symbolSize: 3,
            data: [],
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
        ],
      },

      option3: {
      tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
          // dataView: { show: true, readOnly: false },
          magicType: { show: true, title: '' , type: ["line", "bar", "pie"] },
          restore: { show: false },
          saveAsImage: { show: true, title: 'Save'  },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            symbolSize: 3,
            showSymbol: true,
            data: [],
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            symbolSize: 3,
            showSymbol: true,
            data: [],
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            symbolSize: 3,
            showSymbol: true,
            data: [],
          },
        ],
      },

      option4: {
        tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED", "PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar", "pie"] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 3,
            data: [],
          },
        ],
      },

    };
  },
apollo: {
    instrumentsHistory: {
      query: INSTRUMENTS_HISTORY_QUERY,
      variables() {
        return {
          instruments: [
            `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_PE_${this.strikedata}`,
            `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_CE_${this.strikedata}`,
          ],
        };
      },
      update: (data) => {
        instrumentsHistory = data.instrumentsHistory;
        return instrumentsHistory;
      },
      result() {
        console.log(instrumentsHistory)
        if (this.selectedIndex == 0) {
          this.option.xAxis.data = [];
          this.option.series[0].data = [];
          this.option.series[1].data = [];
          this.option.series[2].data = [];
        }
        else if (this.selectedIndex == 1) {
          this.option2.xAxis.data = [];
          this.option2.series[0].data = [];
          this.option2.series[1].data = [];
          this.option2.series[2].data = [];
        }
      else if (this.selectedIndex == 2){
          this.option3.xAxis.data = [];
          this.option3.series[0].data = [];
          this.option3.series[1].data = [];
          this.option3.series[2].data = [];
      }
      
      else if (this.selectedIndex == 3){
          this.option4.xAxis.data = [];
          this.option4.series[0].data = [];
          this.option4.series[1].data = [];
          this.option4.series[2].data = [];
      }
        var pedata = instrumentsHistory[0].data;
        var cedata = instrumentsHistory[1].data;
        var lastTradeTime = [];
        var pedata2 = [];
        var cedata2 = [];
        var combined = [];
        pedata.forEach((elementPe) => {
          cedata.forEach((elementCe) => {
              if (elementPe.LASTTRADETIME == elementCe.LASTTRADETIME) {
              lastTradeTime.push(elementCe);
              pedata2.push(elementPe);
              cedata2.push(elementCe);
              combined.push((elementPe.CLOSE + elementCe.CLOSE).toFixed(2));
            }
          });
        });
        lastTradeTime.forEach((element) => {
           if (this.selectedIndex == 0) {
           this.option.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }
           else if (this.selectedIndex == 1) {
           this.option2.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }
           else if(this.selectedIndex == 2){
           this.option3.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }
           else if(this.selectedIndex == 3){
           this.option4.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
           }
         
        });
        pedata2.forEach((element) => {
          if (this.selectedIndex == 0) {
            this.option.series[1].data.push(element.CLOSE);
          } 
          else if (this.selectedIndex == 1) {
            this.option2.series[1].data.push(element.CLOSE);
          }
          else if (this.selectedIndex == 2) {
            this.option3.series[1].data.push(element.CLOSE);
          }
          else if (this.selectedIndex == 3) {
            this.option4.series[1].data.push(element.CLOSE);
          }
        });
        cedata2.forEach((element) => {
          if (this.selectedIndex == 0) {
            this.option.series[2].data.push(element.CLOSE);
          } 
          else if (this.selectedIndex == 1) {
            this.option2.series[2].data.push(element.CLOSE);
          }
          else if (this.selectedIndex == 2) {
            this.option3.series[2].data.push(element.CLOSE);
          }
          else if (this.selectedIndex == 3) {
            this.option4.series[2].data.push(element.CLOSE);
          }
        });
        combined.forEach((element) => {
          if (this.selectedIndex == 0) {
            this.option.series[0].data.push(element);
          } 
          else if (this.selectedIndex == 1) {
            this.option2.series[0].data.push(element);
          }
          else if (this.selectedIndex == 2) {
            this.option3.series[0].data.push(element);
          }
          else if (this.selectedIndex == 3) {
            this.option4.series[0].data.push(element);
          }
        });
      },
    },
    // subscribedInstruments: {
    //   query: INSTRUMENTS_QUERY,
    //   subscribeToMore: {
    //     document: INSTRUMENTS_SUBSCRIPTION,
    //     variables() {
    //       return {
    //         instruments: [
    //          this.strikeprice ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_PE_${this.strikeprice}` : '',
    //          this.strikeprice2 ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_PE_${this.strikeprice2}` : '',
    //          this.strikeprice3 ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_PE_${this.strikeprice3}` : '',
    //          this.strikeprice4 ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_PE_${this.strikeprice4}` : '',
    //          this.strikeprice ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_CE_${this.strikeprice}` : '',
    //          this.strikeprice2 ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_CE_${this.strikeprice2}` : '',
    //          this.strikeprice3 ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_CE_${this.strikeprice3}` : '',
    //          this.strikeprice4 ? `OPTIDX_${this.instrument}_${this.expirydate ? this.expirydate.replace("21", "2021") : ''}_CE_${this.strikeprice4}` : '',
    //       ],
    //       };
    //     },
    //     // Mutate the previous result
    //     updateQuery: (previousResult, { subscriptionData }) => {
    //       return subscriptionData.data || subscribedInstruments;
    //     },
    //     result(previousResult, { subscriptionData }) {
    //       console.log(subscriptionData.data)
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.strikeprice}`){
    //          localStorage.setItem("st1PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart1()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.strikeprice}`){
    //          localStorage.setItem("st1CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart1()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.strikeprice2}`){
    //          localStorage.setItem("st2PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart2()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.strikeprice2}`){
    //          localStorage.setItem("st2CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart2()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.strikeprice3}`){
    //          localStorage.setItem("st3PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart3()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.strikeprice3}`){
    //          localStorage.setItem("st3CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart3()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_PE_${this.strikeprice4}`){
    //          localStorage.setItem("st4PeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart4()
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER == `OPTIDX_${this.instrument}_${this.expirydate.replace("21", "2021")}_CE_${this.strikeprice4}`){
    //          localStorage.setItem("st4CeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //          this.chart4()
    //       }
    //     },
    //   },
    // },
  },
  methods: {
    async initialFunctions() {
         await this.$store.dispatch('symbols/getSymbolsList');
         await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
         await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
         this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
         this.strikeprice = await this.getCurrentPrice(this.currentStrikePrice)
      },

      chart1() {
         var st1PeData = JSON.parse(localStorage.getItem("st1PeData"))
          var st1CeData = JSON.parse(localStorage.getItem("st1CeData"))
          // console.log(st1PeData.LASTTRADETIME === st1CeData.LASTTRADETIME)
          if(!!st1PeData.LASTTRADETIME && !!st1CeData.LASTTRADETIME && st1PeData.LASTTRADETIME == st1CeData.LASTTRADETIME) {
            if(this.strikeprice) {
             console.log("-------->check -1")
             this.option.xAxis.data.push(this.unixToTime(st1CeData.LASTTRADETIME))
             this.option.series[0].data.push((st1PeData.CLOSE + st1CeData.CLOSE).toFixed(2))
             this.option.series[1].data.push(st1PeData.CLOSE)
             this.option.series[2].data.push(st1CeData.CLOSE)
             }
          }
      },
      chart2() {
        var st2PeData = JSON.parse(localStorage.getItem("st2PeData"))
          var st2CeData = JSON.parse(localStorage.getItem("st2CeData"))
          if(!!st2PeData.LASTTRADETIME && !!st2CeData.LASTTRADETIME && st2PeData.LASTTRADETIME === st2CeData.LASTTRADETIME) {
             if(this.strikeprice2) {
                console.log("-------->check -2")
                this.option2.xAxis.data.push(this.unixToTime(st2CeData.LASTTRADETIME))
                this.option2.series[0].data.push((st2PeData.CLOSE + st2CeData.CLOSE).toFixed(2))
                this.option2.series[1].data.push(st2PeData.CLOSE)
                this.option2.series[2].data.push(st2CeData.CLOSE)
             }
          }
      },
      chart3() {
        var st3PeData = JSON.parse(localStorage.getItem("st3PeData"))
          var st3CeData = JSON.parse(localStorage.getItem("st3CeData"))
          if(!!st3PeData.LASTTRADETIME && !!st3CeData.LASTTRADETIME && st3PeData.LASTTRADETIME === st3CeData.LASTTRADETIME) {
             if(this.strikeprice3) {
             this.option3.xAxis.data.push(this.unixToTime(st3CeData.LASTTRADETIME))
             this.option3.series[0].data.push((st3PeData.CLOSE + st3CeData.CLOSE).toFixed(2))
             this.option3.series[1].data.push(st3PeData.CLOSE)
             this.option3.series[2].data.push(st3CeData.CLOSE)
             }
          }
      },
      chart4() {
         var st4PeData = JSON.parse(localStorage.getItem("st4PeData"))
          var st4CeData = JSON.parse(localStorage.getItem("st4CeData"))
           if(!!st4PeData.LASTTRADETIME && !!st4CeData.LASTTRADETIME && st4PeData.LASTTRADETIME === st4CeData.LASTTRADETIME) {
             if(this.strikeprice4) {
             this.option4.xAxis.data.push(this.unixToTime(st4CeData.LASTTRADETIME))
             this.option4.series[0].data.push((st4PeData.CLOSE + st4CeData.CLOSE).toFixed(2))
             this.option4.series[1].data.push(st4PeData.CLOSE)
             this.option4.series[2].data.push(st4CeData.CLOSE)
             }
           }
      },
  },
  computed: {
    ...mapState("symbols", ["symbolsList", "expiryList", "strickPriceList","currentStrikePrice"]),
  },
  watch: {
    instrument: function (val) {
      this.initialFunctions();
    },
    expirydate: function (val) {
    //  this.initialFunctions();
    this.strikeprice = this.strikeprice2 = this.strikeprice3 =this.strikeprice4 = null
    },
    strikeprice: function (val) {
      this.strikedata = val;
      this.selectedIndex = 0;
    },
    strikeprice2: function (val) {
      this.strikedata = val;
      this.selectedIndex = 1;
    },
    
    strikeprice3: function (val) {
      this.strikedata = val;
      this.selectedIndex = 2;
    },
    strikeprice4: function (val) {
      this.strikedata = val;
      this.selectedIndex = 3;
    },
  },

  mounted() {
    this.initialFunctions()
    var isDark = localStorage.getItem("dark_theme");
    var theme = isDark == "true" ? "dark" : "light";
    // this.option = Object.assign(
    // this.global.chart.defaultTheme[theme],
    // this.option);
    this.option.series[2].color = this.option2.series[2].color = this.option3.series[2].color = this.option4.series[2].color = "#4caf50";
    this.option.series[1].color = this.option2.series[1].color = this.option3.series[1].color = this.option4.series[1].color = "#ff5e5e";
  },
};
</script>