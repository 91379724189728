<style scoped>
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}
</style>

<template>
  <div>
   <v-flex class="px-2">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-select height="30" dense :items="symbolsList" v-model="instrument"  outlined  hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense disabled v-model="selectdate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" hide-details label="Select Date" v-on="on" ></v-text-field>
                    </template>
                    <v-date-picker v-model="selectdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectdate)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-select v-model="ceStrikeprice" :items="strickPriceList" outlined dense hide-details label="Select CE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-select v-model="peStrikeprice" :items="strickPriceList" outlined dense hide-details label="Select PE Strike" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-select v-model="timedata" :items="timeinterval" outlined dense hide-details label="Time Interval" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
              <v-col class="" cols="6" sm="2" xs="12">
                <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
    <v-flex xs12 class="pa-2">
      <v-container fluid class="charthalfview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12>
            <v-card class="pa-2 a-box">
              <div class="text-center mt-1 chartheader-text"><p class="mb-1">Single Strangle - {{instrument }} {{ceStrikeprice}}CE X {{peStrikeprice}}PE</p>
              <!-- <div class="pt-2">{{instrument }} {{ceStrikeprice}}CE X {{peStrikeprice}}PE</div>  -->
              </div>
              <v-chart class="chart" autoresize :options="combinedChart" />
            </v-card>
          </v-flex>
        </v-layout
      ></v-container>
    </v-flex>
    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid class="charthalfview">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md6 lg6 xl6 class="pa-2 px-0 pt-0 pb-2">
            <v-card  class="pa-2 a-box">
               <div class="text-center mt-1 chartheader-text"><p class="mb-1">Open Interest</p></div>
              <v-chart class="chart" autoresize :options="peceoiChart" />
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6 class="pa-2 pr-0 pt-0">
            <v-card  class="pa-2 a-box">
              <div class="text-center mt-1 chartheader-text"><p class="mb-1">Call Put Price</p></div>
              <v-chart class="chart" autoresize :options="peceCloseChart" />
            </v-card>
          </v-flex> </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>

<script>
import VChart from "vue-echarts";
import "@/plugins/echart";
import { mapState } from "vuex"
import commonFunctions from "@/mixins/commonFunctions";
import { gql } from "graphql-tag";

const INSTRUMENTS_HISTORY_QUERY = gql`
  query instrumentsHistory($instruments: [String]) {
    instrumentsHistory(instruments: $instruments) {
      instrument
      data {
        INSTRUMENTIDENTIFIER
        OPEN
        HIGH
        LOW
        CLOSE
        OPENINTEREST
        TRADEDQTY
        EXCHANGE
        LASTTRADETIME
      }
    }
  }
`;
const INSTRUMENTS_SUBSCRIPTION = gql`
  subscription subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
const INSTRUMENTS_QUERY = gql`
  query subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;

let subscribedInstruments = {
  INSTRUMENTIDENTIFIER: "",
  OPEN: "0.0",
  HIGH: "0.0",
  LOW: "0.0",
  CLOSE: "0.0",
};

let instrumentsHistory = {};
export default {
  mixins:[commonFunctions],
  components: {
    VChart,
  },
  // provide: {
  //   [THEME_KEY]: "light",
  // },

  data() {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      submenu: false,
      modal: false,
      menu2: false,
      instrument: "BANKNIFTY",
      // expirydate: "11NOV21",
      ceStrikeprice: null,
      peStrikeprice: null,
      timedata: "1MIN",
      timeinterval: ["1MIN"],

      combinedChart: {
       tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["COMBINED","CALL PRICE","PUT PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "COMBINED",
            type: "line",
            smooth: true,
            data: [],
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: 0.6,
            animation: true,
            data: [],
            symbolSize: 3,
            symbol: "emptycircle",
          },
          {
            name: "PUT PRICE",
            type: "line",
            smooth: 0.6,
            animation: true,
            data: [],
            symbolSize: 3,
            symbol: "emptycircle",
          },

        ],
      },

      peceoiChart: {
      tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["PUT OI", "CALL OI"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "PUT OI",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "CALL OI",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
        ],
      },

      peceCloseChart: {
      tooltip: { 
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: true},
        borderWidth: '0',
        textStyle: {
            color: '#ffffff'
        }
        },
        legend: { data: ["PUT PRICE", "CALL PRICE"], bottom: "1%" },
        grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow", shadowColor: "#E6E5E4",
          },
          data: [],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "PUT PRICE",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
          {
            name: "CALL PRICE",
            type: "line",
            smooth: true,
            symbolSize: 3,
            data: [],
          },
        ],
      },
    };
  },

   computed: {
      ...mapState('symbols',['symbolsList','expiryList','strickPriceList','ceStrikePriceList','peStrikePriceList','currentStrikePrice','currentExpiryDate']),
      expirydate: {
        get:function() {return this.currentExpiryDate},
        set:function(val) {}
      }
    },

    watch: { 
       instrument: function(val) {
        //  this.initialFunctions()
       },
       expirydate: function(val) {
          // this.initialFunctions()
          this.ceStrikeprice = this.peStrikeprice = '';
       },
    },

    methods: {
       getCurrentPrice (value) {
         return this.strickPriceList.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
       },

       triggerMyQuery () {
         this.skipQuery = false
       },

      async initialFunctions() {
         await this.$store.dispatch('symbols/getSymbolsList');
         await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
         await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
         this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
         this.ceStrikeprice = this.peStrikeprice  = await this.getCurrentPrice(this.currentStrikePrice)
        //  await this.$store.dispatch('instrumentHistory/getInstrumentHistory', {instrument:this.instrument , expiry: this.expirydate, strikeprice:this.strikeprice})
      }
    },

    apollo: {
    instrumentsHistory: {
      query: INSTRUMENTS_HISTORY_QUERY,
      variables() {
        return {
          instruments: [
            `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21', '2021') : ''}_PE_${this.peStrikeprice}`,
            `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21', '2021') : ''}_CE_${this.ceStrikeprice}`,
          ],
        };
      },

      update: (data) => {
        instrumentsHistory = data.instrumentsHistory;
        return instrumentsHistory;
      },

      result() {
        console.log(instrumentsHistory)
        this.combinedChart.xAxis.data = [];
        this.peceoiChart.xAxis.data = [];
        this.peceCloseChart.xAxis.data = [];
        this.combinedChart.series[0].data = [];
        this.peceoiChart.series[0].data = [];
        this.peceoiChart.series[1].data = [];
        this.peceCloseChart.series[0].data = [];
        this.peceCloseChart.series[1].data = [];
        this.combinedChart.series[1].data = [];
        this.combinedChart.series[2].data = [];
        var pedata = instrumentsHistory[0].data;
        var cedata = instrumentsHistory[1].data;
        var lastTradeTime = [];
        var pedata2 = [];
        var cedata2 = [];
        var combined = [];
        pedata.forEach((elementPe) => {
          cedata.forEach((elementCe) => {
            if (elementPe.LASTTRADETIME == elementCe.LASTTRADETIME) {
              lastTradeTime.push(elementCe);
              pedata2.push(elementPe);
              cedata2.push(elementCe);
              combined.push((elementPe.CLOSE + elementCe.CLOSE).toFixed(2));
              //
              this.peceoiChart.series[0].data.push(elementPe.OPENINTEREST);
              this.peceoiChart.series[1].data.push(elementCe.OPENINTEREST);
            }
          });
        });

        lastTradeTime.forEach((element) => {
          this.combinedChart.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
          this.peceoiChart.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
          this.peceCloseChart.xAxis.data.push(this.unixToTime(element.LASTTRADETIME));
        });

        pedata2.forEach((element) => {
          this.combinedChart.series[2].data.push(element.CLOSE);
          this.peceCloseChart.series[0].data.push(element.CLOSE);
        });

        cedata2.forEach((element) => {
           this.combinedChart.series[1].data.push(element.CLOSE);
          this.peceCloseChart.series[1].data.push(element.CLOSE);
        });

        combined.forEach((element) => {
          this.combinedChart.series[0].data.push(element);
        });
      },

      // skip() {
      // return this.skipQuery
      // },
    },

    // subscribedInstruments: {
    //   query: INSTRUMENTS_QUERY,
    //   subscribeToMore: {
    //     document: INSTRUMENTS_SUBSCRIPTION,
    //     // Variables passed to the subscription. Since we're using a function,
    //     // they are reactive
    //     variables() {
    //       return {
    //         instruments: [
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21', '2021') : ''}_PE_${this.peStrikeprice}`,
    //           `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21', '2021') : ''}_CE_${this.ceStrikeprice}`,
    //         ],
    //       };
    //     },

    //     // Mutate the previous result
    //     updateQuery: (previousResult, { subscriptionData }) => {
    //       return subscriptionData.data || subscribedInstruments;
    //     },

    //     result(previousResult, { subscriptionData }) {
    //       console.log(subscriptionData.data)
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER.includes('PE')) {
    //          localStorage.setItem("wsPeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //       }
    //       if(subscriptionData.data.subscribedInstruments.INSTRUMENTIDENTIFIER.includes('CE')) {
    //          localStorage.setItem("wsCeData",JSON.stringify(subscriptionData.data.subscribedInstruments))
    //       }
    //       var peData = JSON.parse(localStorage.getItem("wsPeData"))
    //       var ceData = JSON.parse(localStorage.getItem("wsCeData"))
    //       if(peData.LASTTRADETIME == ceData.LASTTRADETIME) {
    //          this.combinedChart.xAxis.data.push(this.unixToTime(ceData.LASTTRADETIME))
    //          this.peceoiChart.xAxis.data.push(this.unixToTime(ceData.LASTTRADETIME))
    //          this.peceCloseChart.xAxis.data.push(this.unixToTime(ceData.LASTTRADETIME))
    //          this.peceoiChart.series[0].data.push(peData.OPENINTEREST)
    //          this.peceoiChart.series[1].data.push(ceData.OPENINTEREST)
    //          this.peceCloseChart.series[0].data.push(peData.CLOSE)
    //          this.peceCloseChart.series[1].data.push(ceData.CLOSE)
    //          this.combinedChart.series[1].data.push(ceData.CLOSE);
    //          this.combinedChart.series[2].data.push(peData.CLOSE);
    //          console.log("peclose",peData.CLOSE)
    //          console.log("ceclose",ceData.CLOSE)
    //          console.log("PLUSCEPE",peData.CLOSE + ceData.CLOSE)
    //          this.combinedChart.series[0].data.push((peData.CLOSE + ceData.CLOSE).toFixed(2))
    //       }
    //       }
    //     }
    // },
  },

  mounted() {
    // this.combinedChart.color = this.global.chart.defaultTheme.color;
    var isDark = localStorage.getItem("dark_theme");
    var theme = (isDark == 'true')?'dark':'light';
    // this.combinedChart = Object.assign(this.global.chart.defaultTheme[theme],this.option);
    this.combinedChart.series[1].color = this.peceoiChart.series[1].color = this.peceCloseChart.series[1].color = "#4caf50";
    this.combinedChart.series[2].color = this.peceoiChart.series[0].color = this.peceCloseChart.series[0].color = "#ff5e5e";
    this.initialFunctions()
  },
};
</script>

